@import 'styles/brand-colors';
@import 'styles/font-styles';

body {
  background-color: $midnight-10;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}

.pointer {
  &:hover {
    cursor: pointer;
  }
}

.as-link {
  text-decoration: underline;

  &:hover {
    cursor: pointer;
  }
}
