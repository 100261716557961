.dialog-container {
    padding: 16px;
}

.MuiDialogTitle-root {
    padding: 0px !important;
}

.MuiDialogActions-root{
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    padding-bottom: 24px !important;
}

.close-button {
    margin: 0px;
    padding: 4px !important;
}

.modal-header {
    display: flex !important;
    justify-content: space-between !important;
    align-items: flex-end !important;
    padding: 8px 16px !important;
}

.modal-title {
    color: #524e4a;
    font-size: 18px
}

.modal-body {
    text-align: center;
    color: #707579;
    font-size: 18px;
}

.confirmation-modal-icon > path {
    fill: #DDAD49;
}
