.titleColor {
   color: #335C6D;
   font-weight: bold;
}

.mainBackgroundColor {
   background-color: white;
}

.fontBold {
   font-weight: bold;
}

.fontLight {
   font-weight: lighter;
}

.buttonColor span {
   color: #008c96;

}

.buttonPrimary {
   color: white;
   font-weight: bold;
   background-color: #1B78AB;
}

.alert {
   box-sizing: border-box;
   border-radius: 4px;
   border: 1px solid black;
   margin: 0px 18px 0px 18px;
   padding: 30px;
}

.tableMargin {
   box-sizing: border-box;
   border-radius: 4px;
   border: 1px solid #CCD6DB;
   margin: 18px;
}
