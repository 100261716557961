.innerMargin
{
   margin: 20px;
}

.innerMarginWithBorder
{
   margin: 20px;
   border: 1px solid #CCD6DB;
}

.buttonMargin
{
   margin-right: 10px;
}
